<script setup lang="ts">
import type { CmsElementZebraCmsExpandableText } from "~/composables/useCustom";

const props = defineProps<{
  content: CmsElementZebraCmsExpandableText;
}>();
const useHeight = ref(900);
const { headlineText, buttonRight, buttonLeft, mainText, backgroundMobile, backgroundDesktop, height } = props.content?.config

onMounted(() => {
  if(!height || height.value === '') {

  } else {
    useHeight.value = height.value;
  }
})

</script>
<template>
  <section class="relative">
    <figure v-if="useHeight" :style="'height:'+useHeight+'px'">
      <template v-if="backgroundDesktop.value && backgroundMobile.value">
        <NuxtImg format="webp" loading="lazy" v-if="backgroundDesktop.value" :src="backgroundDesktop.value.url" class="img-cover hidden md:block" alt="" width="1680" densities="x1" />
        <NuxtImg format="webp" loading="lazy" v-if="backgroundMobile.value" :src="backgroundMobile.value.url" class="img-cover md:hidden" alt="" width="768" densities="x1"/>
      </template>
      <template v-else-if="backgroundDesktop.value">
        <NuxtImg format="webp" loading="lazy" :src="backgroundDesktop.value.url" class="img-cover" alt="" width="1680" densities="x1" />
      </template>
      <template v-else-if="backgroundMobile.value">
        <NuxtImg format="webp" loading="lazy" :src="backgroundMobile.value.url" class="img-cover" alt=""  width="768" densities="x1"/>
      </template>
    </figure>
    <div class="w-full absolute bottom-[120px] top-[60%] -translate-y-2/4 ">
      <div class="container">
        <div class="overlayTxtSize text-white">
          <h3 class="text-[30px] md:text-[40px] text-white mb-5 font-bold uppercase">{{headlineText.value}}</h3>
          <div class="mb-8" v-html="mainText.value"></div>
          <div class="flex flex-wrap gap-5">
            <SharedButton v-if="buttonLeft" btnType="secondary" :button="buttonLeft.value"></SharedButton>
            <SharedButton v-if="buttonRight" btnType="outline" :button="buttonRight.value"></SharedButton>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
